import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

import Header from "../components/header"
import Footer from "../components/footer"

export const query = graphql`
  query Get404En {
    strapiPageNotFound(locale: { eq: "en" }) {
      Heading
      Content {
        data {
          Content
        }
      }
    }

    site {
      siteMetadata {
        title
      }
    }

    strapiContact(locale: { eq: "en" }) {
      locale
      localizations {
        data {
          attributes {
            slug
            locale
          }
        }
      }
      slug
    }

    strapiFooter(locale: { eq: "en" }) {
      ContactText
      Heading
      RightsClaim
      SocialHeading
      Text
      Secondary {
        Link
        Text
      }
      Footer {
        Link
        Text
      }
    }

    strapiHeader(locale: { eq: "en" }) {
      MobileContactButtonText
      MobileContactHeading
      MobileContactText
      Links {
        Link
        Text
      }
      ContactText
    }

    strapiGeneralTranslation(locale: { eq: "en" }) {
      AllRightsReserved
      BackToBlog
      By
      Client
      CopiedToClipboard
      Keys
      ReadMore
      ScrollDownOrJustUse
      ViewProject
      CookieSettings
    }
  }
`

const NotFoundPage = ({ data }) => (
  <>
    <Header
      locale={"en"}
      siteTitle={data.site.siteMetadata?.title || `Title`}
      data={data.strapiHeader}
      contactSlug={data.strapiContact.slug}
    />
    <Layout>
      <Seo title="404: Not found" />
      <div className={"page-not-found"}>
        <h1>{data.strapiPageNotFound.Heading}</h1>
        <ReactMarkdown
          source={data.strapiPageNotFound.Content?.data?.Content}
          escapeHtml={false}
        >
          {data.strapiPageNotFound.Content?.data?.Content}
        </ReactMarkdown>
      </div>
    </Layout>
    <Footer
      contactSlug={data.strapiContact.slug}
      cookieSettingsText={data.strapiGeneralTranslation.CookieSettings}
      data={data.strapiFooter}
    />
  </>
)

export default NotFoundPage
